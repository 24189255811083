/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h2: "h2",
    div: "div",
    code: "code",
    ul: "ul",
    li: "li",
    pre: "pre"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "The Software Development Kit (SDK) gives you tools to build integrations to Signatu using\nthe ", React.createElement(_components.a, {
    href: "https://reactjs.org"
  }, "React UI library"), ". React 16.8 or higher is required."), "\n", React.createElement(_components.p, null, "See ", React.createElement(_components.a, {
    href: "/docs/api/v0/sdk/react/styles"
  }, "Theming"), " for information about how to style the components."), "\n", React.createElement(_components.h2, {
    id: "properties",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#properties",
    "aria-label": "properties permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Properties"), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "<PolicyButton>"), " is configured with the following properties:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "id"), ": The ", React.createElement(_components.code, null, "id"), " of the Privacy Policy from your Signatu account."), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "color"), ": “white”, “blue”, “black” or “gray”."), "\n"), "\n", React.createElement(_components.h2, {
    id: "example",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#example",
    "aria-label": "example permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Example"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "import React from 'React';\n...\nimport {PolicyButton} from '@signatu/sdk/react/policy';\nimport {\n  ThemeProvider,\n  createTheme\n} from '@signatu/sdk/esm/react/styles';\n\nconst config = {\n  accessToken: 'MY_ACCESS_TOKEN'\n}\nconst myTheme = {\n  palette: {\n    primary: {\n      color: \"blue\"\n    }\n  }\n}\n/* Now embed the policy button as follows */\n<ThemeProvider theme={createTheme(myTheme)}>\n    <PolicyButton policyId=\"88223ee422c300ff\" />\n</ThemeProvider>\n\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
